<template>
	<v-select
		v-model="treeStructureId"
		:items="list"
		item-text="name"
		item-value="id"
		:label="$t('customer.general.catalog_tree_structure')"
		:loading="loading"
		@input="onInput"
	/>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'TreeStructureSelector',
	mixins: [CustomersManagerModuleGuard],
	props: {
		value: {
			type: Number,
			required: true
		}
	},
	data: function () {
		return {
			list: [],
			loading: false,
			treeStructureId: null
		}
	},
	watch: {
		vendorId: {
			handler: function (val) {
				if (val) {
					this.getCatalogTreeStructures(val)
				}
			},
			immediate: true
		},
		value: {
			handler: function (val) {
				if (val) {
					this.treeStructureId = val
				}
			},
			immediate: true
		}
	},
	methods: {
		getCatalogTreeStructures: function (vendorId) {
			this.loading = true
			this.list = []
			this.service
				.getCatalogTreeStructures(vendorId)
				.then(res => {
					this.list = res
				})
				.finally(() => {
					this.loading = false
				})
		},
		onInput: function (event) {
			this.$emit('input', event)
		}
	}
}
</script>
